<template>
  <div class="consultingManagement">
    <ds-header title="资讯内容管理"></ds-header>
    <a-form layout="inline" style="max-width: 1180px;">
      <a-form-item label="资讯标题：">
        <a-input v-model.trim="searchForm.consultingTitle" placeholder="请输入" class="inputClass" />
      </a-form-item>
      <a-form-item label="资讯类型:">
        <a-select placeholder="请选择资讯类型"  mode="multiple" v-model.trim="searchForm.type" style="width: 200px"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode">
          <a-select-option :value="item.id" v-for="item in consultingType" :key="item.id">
            {{ item.value }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="展示项目:">
        <!-- <a-select placeholder="请选择展示项目" mode="multiple" v-model="searchForm.projectIds" style="width: 200px"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode">
          <a-select-option v-for="option in projectList" :key="option.id" :value="option.id">
            {{ option.project_name }}
          </a-select-option>
        </a-select> -->
        <el-cascader v-model="searchForm.projectIds"
              ref="cascader"
              :collapse-tags = "true"
              :options="projectList"
              :props="{emitPath:false, multiple: true,checkStrictly:true, label: 'project_name', children: 'child', value: 'id' }" size="small"
              clearable>
        </el-cascader>
      </a-form-item>
      <a-form-item label="所属模块">
        <a-input v-model.trim="searchForm.module_name" placeholder="请输入" class="inputClass" />
      </a-form-item>
      <a-form-item label="状态：">
        <a-select
          v-model.trim="searchForm.status"
          class="searchInput"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option v-for="option in consultingStatus" :key="option.id" :value="option.id">{{
              option.value
            }}</a-select-option>
        </a-select>
      </a-form-item>
      <span>
        <a-button type="primary" @click="search" style="margin-top: 3px"> <a-icon type="search" />查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm">重置</a-button>
      </span>
    </a-form>
    <div style="padding-top: 15px">
      <a-button type="primary" @click="addConsulting"><a-icon type="plus" />新建资讯</a-button>
    </div>
    <div style="padding-top: 15px">
      <a-table
        :rowKey="(record, index) => index"
        :columns="table.columns"
        :data-source="table.dataInfo"
        :pagination="table.pagination.total ? table.pagination : false"
        @change="pageChange"
        bordered
        :scroll="{ x: 1500 ,y: height}"
      >
        <div class="title" slot="consult_title" slot-scope="text">
          <a-tooltip>
            <template slot="title">
              <span>{{ text }}</span>
            </template>
            <div>
              {{ text }}
            </div>
          </a-tooltip>
        </div>
        <div class="title" slot="module_name_str" slot-scope="text">
          <a-tooltip>
            <template slot="title">
              <span>{{ text }}</span>
            </template>
            <div>
              {{ text?text:'-' }}
            </div>
          </a-tooltip>
        </div>
        <div class="title" slot="project_name_str" slot-scope="text">
          <a-tooltip>
            <template slot="title">
              <span>{{ text }}</span>
            </template>
            <div>
              {{ text?text:'-' }}
            </div>
          </a-tooltip>
        </div>
        <span slot="action" slot-scope="text, record" class="action">
          <a-button type="link" class="btnClass" @click="info(record)"> 查看 </a-button>
          <a-button type="link" class="btnClass" @click="releaseorWithdraw(text)">
            {{ text.consult_state == 1 ? "取消发布" : "发布" }}
          </a-button>
          <a-button type="link" class="btnClass" @click="edit(record)"> 编辑 </a-button>
          <a-button type="link" class="btnClass" @click="deleteById(record)"> 删除 </a-button>
        </span>
      </a-table>
    </div>
    <ConfirmBoxModule ref="confirmBoxModule"></ConfirmBoxModule>
  </div>
</template>

<script>
import ConfirmBoxModule from "@/components/confirmBoxModule";
import ConfirmConfig from "@/utils/confirmConfig";
import { consultingManagementList, consultingPublish, consultingDelete, selectTypeListByUserAuth,getShunyiAllProject } from "@/api/consultingManagement";
import moment from "moment";
import pageRules from "@/utils/pagingConfig/pageUtils.js";
import {mapState} from "vuex";
export default {
  name: "ConsultingContent",
  computed: {
    ...mapState({
      // projectList: (state) => state.common.projectList
    }),
  },
  data() {
    return {
      searchForm: {
        projectIds: [],
        consultingTitle: "",
        module_name: "",
        status: undefined,
        type: undefined,
      },
      consultingStatus: [
        { value: "已发布", id: "1" },
        { value: "草稿", id: "2" },
        // { value: "仅保存", id: "3" },
      ],
      consultingType: [],
      table: {
        dataInfo: [],
        columns: [
          {
            title: "资讯标题",
            dataIndex: "consult_title",
            key: "consult_title",
            width:220,
            scopedSlots: { customRender: "consult_title" },
          },
          {
            title: "资讯类型",
            dataIndex: "consult_type_name",
            key: "consult_type_name",
            width:150
          },
          {
            title: '展示项目',
            dataIndex: 'project_name_str',
            scopedSlots: { customRender: "project_name_str" },
            width: 200
          },
          {
            title: '所属模块',
            dataIndex: 'module_name_str',
            width: 200,
            scopedSlots: { customRender: "module_name_str" },
            // customRender(text) {
            //   return text ?text : "-";
            // },
          },
          {
            title: '发布时间',
            dataIndex: 'show_time',
            width:180
          },
          {
            title: "状态",
            dataIndex: "consult_state",
            key: "consult_state",
            customRender(text) {
              return text === 1 ? "已发布" : text === 2 ? "草稿" : "-";
            },
            width:80
          },
          {
            title: "展示顺序",
            dataIndex: "sort",
            key: "sort",
            customRender(text) {
              return text !=null  ? text : "-";
            },
            width:100
          },
          {
            title: '最后修改时间',
            dataIndex: 'update_time',
            width:180
          },
          {
            title: '最后修改人',
            dataIndex: 'user_name',
            width:150
          },
          {
            title: '创建时间',
            dataIndex: 'create_time',
            width:180
          },
          {
            title: "创建人",
            dataIndex: "create_name",
            key: "create_name",
            width:150
          },
          {
            title: "操作",
            key: "action",
            width: 205,
            className: "action-ql-list",
            fixed: 'right',
            scopedSlots: { customRender: "action" },
          },
        ],
        //分页数据
        pagination: {
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
      },
      projectList:[],
      height:''
    };
  },
  // 进入路由添加缓存
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      console.log('=========>',vm);
      vm.$store.dispatch("operationCachedRoutes/updateAliveRoutes", ["ConsultingContent"]);
      if (from.query) {
        vm.consultingList();
      }
    });
  },
  // 离开清除缓存
  beforeRouteLeave(to, from, next) {
    console.log('========>',to.path);
    if (to.path !== "/consultingManagement/content/add") {
      this.$store.dispatch("operationCachedRoutes/updateAliveRoutes", []);
    }
    next();
  },
  async created() {
    await this.getTypeList();
    getShunyiAllProject({ check_type: true }).then(res => {
      this.projectList = res.data
      console.log(res, '================>');
    })
  },
  mounted() {
    this.consultingList();
    let handleResize = ()=> {
      this.height = window.innerHeight-341-64-40-30
      console.log(this.height);
      let width = document.getElementsByClassName('consultingManagement')[0].clientWidth;
      if(width>1890){
        this.table.columns[this.table.columns.length-1].fixed = '';
      }else{
        this.table.columns[this.table.columns.length-1].fixed = 'right';
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize()
  },
  components: {
    ConfirmBoxModule,
  },
  methods: {
    /**
     * 获取当前用户拥有的资讯类型
     */
    async getTypeList() {
      const res = await selectTypeListByUserAuth();
      const { code, data } = res
      if (code === '200') {
        this.consultingType = data && data.length && data.map(item => {
          return {
            id: item.consult_type_id,
            value: item.consult_type_name
          }
        })
      }
    },
    // 搜索
    search() {
      this.table.pagination.current = 1;
      this.consultingList();
    },

    // 列表
    async consultingList() {
      let data = {};
      data.consult_title = this.searchForm.consultingTitle;
      data.consult_state = this.searchForm.status;
      data.consult_type_id = this.searchForm.type;
      data.project_id_list = this.searchForm.projectIds;
      data.module_name = this.searchForm.module_name;
      data.page_num = this.table.pagination.current;
      data.page_size = this.table.pagination.pageSize;
      const res = await consultingManagementList(data);
      this.table.dataInfo = res.data.list;
      this.table.pagination.total = res.data.total;
    },

    // 新增
    addConsulting() {
      this.$router.push({
        path: "/consultingManagement/content/add",
        query: {
          type: "add",
        },
      });
    },

    // 编辑
    edit(record) {
      this.$router.push({
        path: "/consultingManagement/content/add",
        query: {
          type: "update",
          id: record.id,
        },
      });
    },

    // 发布or撤回
    async releaseorWithdraw(text) {
      const _this = this;
      let title;
      let content;
      let status;
      if (text.consult_state === 1) {
        title = "确认撤回该资讯";
        content = "撤回后用户不可见该资讯。";
        status = "OUT";
      } else {
        title = "确认发布该资讯";
        content = "发布后用户可见该资讯。";
        status = "PUTAWAY";
      }
      _this.$confirm({
        title: title,
        content: content,
        async onOk() {
          const res = await consultingPublish({ id: text.id, status: status });
          if (res.code === "200") {
            // _this.$message.success("撤回成功!");
            _this.consultingList();
          } else {
            _this.$message.error(res.msg);
          }
        },
        onCancel() {},
      });
    },

    // 查看
    info(record) {
      this.$router.push({
        path: "/consultingManagement/content/add",
        query: {
          type: "info",
          id: record.id,
        },
      });
    },

    // 删除
    async deleteById(record) {
      const _this = this;
      _this.$confirm({
        title: "确认删除该资讯",
        content: "该操作为不可逆操作，请谨慎操作！",
        async onOk() {
          const res = await consultingDelete({ id: record.id });
          if (res.code === "200") {
            _this.table.pagination.current = pageRules.pageRules(
              _this.table.dataInfo.length,
              1,
              _this.table.pagination.current
            );
            _this.$message.success("删除成功!");
            _this.consultingList();
          } else {
            _this.$message.error(res.msg);
          }
        },
        onCancel() {},
      });
    },

    // 重置
    resetSearchForm() {
      this.table.pagination.current = 1;
      this.table.pagination.pageSize = 10;
      this.searchForm = {
        consultingTitle: "",
        module_name:'',
        status: undefined,
        type: undefined,
        projectIds: [],
      };
      this.consultingList();
    },

    // 分页操作
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.consultingList();
    },
  },
};
</script>
<style lang="scss" scoped>
.searchInput {
  width: 200px;
}

.btnClass {
  padding: 0px;
  padding-right: 5px;
}

.imgContainer {
  width: 80px;
  height: 80px;
  text-align: center;
  img {
    width: 100%;
    height: 100%;
  }
}
.title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; // 控制多行的行数
  -webkit-box-orient: vertical;
}
</style>
